import React from 'react'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import custum from '../../../../Assets/images/firerock-daimonds-img/design-custm.webp'
import Button from 'react-bootstrap/Button';
import { Link, NavLink } from 'react-router-dom';
const DesignCustumEngagement = () => {
  return (
    <>
    <div className='fd_design_custom_engagement_rings fd_spacve_one'>
        <Container>
            <Row>
             <Col>
              <div className='fd_custum_engement'>
                <div className='fd_cutumimg'>
                <img src={custum} alt='How to Design a Custom Engagement Ring' className='img-fluid'/>
                </div>
              </div>
             </Col>
             <Col md={6}  sm={12} lg={6} className='d-flex align-items-center'>
            <div className='fd_about_firefocks'> 
            {/* <div className='heading-wrapper'>     */}
            <h2 className='fd_headings'>How to Design a Custom Engagement Ring</h2>
            <p className='fd_paras'>Banded Together Forever</p>
            <NavLink to="/custom-design">  <Button variant="outline-dark" className='firerock_button'>Get Inspired</Button> </NavLink>
                {/* </div> */}
                </div>
         </Col>
            </Row>
        </Container>
    </div>
    </>
  )
}

export default DesignCustumEngagement
