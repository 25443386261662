import React from 'react';
import Container from '../ui/Container';
import Heading from '../ui/Heading';

const Insta = () => {
    return (
        <section className='insta-section section-space pb-0'>
            <Container>
                <Heading>
                    <h2>GET A TOUR OF OUR SOCIAL MEDIA</h2>
                    {/* <h2>Social Media</h2> */}
                    <p>Follow 
                        {/* us on Instagram  */}
                         <a href='https://www.instagram.com/firerock_diamonds/' className='text-dark' target='_blank'> <strong>firerock_diamonds</strong></a> on Instagram to get inspired
                         </p>
                </Heading>
                <iframe src="https://instagram.demobw.live/firerock-diamonds/" frameborder="0" width="100%" class="insta-iframe"></iframe>
            </Container>
        </section>
    )
}

export default Insta;