import React from 'react'
import Container from '../ui/Container'
import Heading from '../ui/Heading'
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'

import Ring from "../../../../Assets/images/firerock-daimonds-img/collection-img/engagement-ring.png";
import Bracelet from "../../../../Assets/images/firerock-daimonds-img/collection-img/bracelets.png";
import Earring from "../../../../Assets/images/firerock-daimonds-img/collection-img/earring.png";
import Necklace from "../../../../Assets/images/firerock-daimonds-img/collection-img/necklaces.png";
import WeddingRing from "../../../../Assets/images/firerock-daimonds-img/collection-img/wedding-ring.png";
import MensJewel from "../../../../Assets/images/firerock-daimonds-img/collection-img/mens.jpg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";


import { Height } from '@material-ui/icons'
import Slider from "react-slick";
import { isMobile, isMobileOnly } from 'react-device-detect'

const ExploreCollection = () => {
    const collectionData = [
        {
            name:"Rings",
            url:"/jewelry/fashion-rings",
            img:Ring,
        },
        {
            name:"Wedding Ring",
            url:"/jewelry/wedding-rings",
            img:WeddingRing,
        },
        {
            name:"Necklaces",
            url:"/jewelry/necklaces",
            img:Necklace,
        },
        {
            name:"Earrings",
            url:"/jewelry/earrings",
            img:Earring,
        },
        {
            name:"Bracelets",
            url:"/jewelry/bracelets",
            img:Bracelet,
        },
        {
            name:"Men's jewelry",
            url:"/jewelry/mens-jewelery",
            img:MensJewel,
        },
    ]

    const collectionSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <FaChevronRight />,
        prevArrow: <FaChevronLeft />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
        ]
    };
    return (
        <section className='explore-collection section-space'>
            <Container>
                <Heading>
                    <h2>Explore Our Collection</h2>
                    <p>Explore Our Exclusive Jewelry Collection</p>
                </Heading>

                <Row>
                    <Col sm={12} md={12} lg={12}>
                       
                        <Slider {...collectionSettings} className='frd_explore_Collection_Slider'>
                            {collectionData.map((val)=>(
                                <div className='frd_collection_inner_box_before_inner'>
                                <div className='item text-center frd_collection_inner_box ' style={{backgroundImage:`url(${val.img})`}}>
                                    <Link to={val.url} >{val.name}</Link>
                                </div>
                                </div>
                                    
                                ) )} 
                        </Slider>
                    </Col>
                    {/* <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/fashion-rings' className='img-box'><Image src={Ring} alt='' /></Link>
                            <Link to='/jewelry/fashion-rings'>Rings</Link>
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/bracelets' className='img-box'><Image src={Bracelet} alt='' /></Link>
                            <Link to='/jewelry/bracelets'>Bracelets</Link>
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/earrings' className='img-box'><Image src={Earring} alt='' /></Link>
                            <Link to='/jewelry/earrings'>Earrings</Link>
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewelry/necklaces' className='img-box'><Image src={Necklace} alt='' /></Link>
                            <Link to='/jewelry/necklaces'>Necklaces</Link>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </section>
    )
}

export default ExploreCollection