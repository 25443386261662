import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import rounds from "../../../../Assets/images/firerock-daimonds-img/round-diamond.png";
import Princess from "../../../../Assets/images/firerock-daimonds-img/princess-diamond.png";
import Cushion from "../../../../Assets/images/firerock-daimonds-img/cushion-diamond.png";
import Emerald from "../../../../Assets/images/firerock-daimonds-img/emerald-diamond.png";
import Oval from "../../../../Assets/images/firerock-daimonds-img/oval-diamond.png";
import Radiant from "../../../../Assets/images/firerock-daimonds-img/radiant-diamond.png";
import Asscher from "../../../../Assets/images/firerock-daimonds-img/asscher-diamond.png";
import Marquise from "../../../../Assets/images/firerock-daimonds-img/marquise-diamond.png";
import Heart from "../../../../Assets/images/firerock-daimonds-img/heart-diamond.png";
import Pear from "../../../../Assets/images/firerock-daimonds-img/pear-diamond.png";
import { Link, NavLink } from "react-router-dom";
import Slider from "react-slick";
import SlickLeft from "../../../../Assets/images/firerock-daimonds-img/right.png";
import SlickRight from "../../../../Assets/images/firerock-daimonds-img/left.png";
import { Image } from "react-bootstrap";
const EdShopDiamonds = () => {
  var fddiamonds = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Image src={SlickLeft} alt="" />,
    nextArrow: <Image src={SlickRight} alt="" />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="fd_shop_diamonds_by_shape">
        <Container className="showcase-container">
          <div className="fd_shpe_heading">
            <h2 className="fd_headings">Shop Diamonds By Shape</h2>
            <p className="fd_paras">
              Looking to buy diamonds? Choose your preferred shape and explore a
              wide selection at our shop.
            </p>
          </div>
          <div className="fd_shapes_desktop d-none d-sm-block">
            <div className="fd_looking_diamomds">
              <Row>
                <Col lg={12} md={12}>
                  <div className="fd_shapes">
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Round">
                          <img src={rounds} alt="fd rounds" />
                          <h5>Round</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Princess">
                          <img src={Princess} alt="fd Princess" />
                          <h5>Princess</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Cushion">
                          <img src={Cushion} alt="fd Cushion" />
                          <h5>Cushion</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Emerald">
                          <img src={Emerald} alt="fd Emerald" />
                          <h5>Emerald</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Oval">
                          <img src={Oval} alt="fd Oval" />
                          <h5>Oval</h5>
                        </NavLink>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="fd_looking_diamomds">
              <Row>
                <Col lg={12} md={12}>
                  <div className="fd_shapes">
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="diamonds/shape=Radiant">
                          <img src={Radiant} alt="fd Radiant" />
                          <h5>Radiant</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Asscher">
                          <img src={Asscher} alt="fd Princess" />
                          <h5>Asscher</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Marquise">
                          <img src={Marquise} alt="fd Cushion" />
                          <h5>Marquise</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Heart">
                          <img src={Heart} alt="fd Emerald" />
                          <h5>Heart</h5>
                        </NavLink>
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="fd_diamd">
                        <NavLink to="/diamonds/shape=Pear">
                          <img src={Pear} alt="fd Oval" />
                          <h5>Pear</h5>
                        </NavLink>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* mobile */}
          <div className="fd_shapes_mobile sliedses d-lg-none  d-md-none">
            <Slider {...fddiamonds}>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Round">
                    <img src={rounds} alt="fd rounds" />
                    <h5>Round</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Princess">
                    <img src={Princess} alt="fd Princess" />
                    <h5>Princess</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Cushion">
                    <img src={Cushion} alt="fd Cushion" />
                    <h5>Cushion</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Emerald">
                    <img src={Emerald} alt="fd Emerald" />
                    <h5>Emerald</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Oval">
                    <img src={Oval} alt="fd Oval" />
                    <h5>Oval</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Radiant">
                    <img src={Radiant} alt="fd Radiant" />
                    <h5>Radiant</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Asscher">
                    <img src={Asscher} alt="fd Princess" />
                    <h5>Asscher</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="diamonds/shape=Marquise">
                    <img src={Marquise} alt="fd Cushion" />
                    <h5>Marquise</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Heart">
                    <img src={Heart} alt="fd Emerald" />
                    <h5>Heart</h5>
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="fd_diamd">
                  <NavLink to="/diamonds/shape=Pear">
                    <img src={Pear} alt="fd Pear" />
                    <h5>Pear</h5>
                  </NavLink>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EdShopDiamonds;
