import React from 'react'
import { Col, Container,Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { Link, NavLink } from 'react-router-dom';
import flames from '../../../../Assets/images/flames-mobile.jpg'
import { isMobileOnly } from 'react-device-detect';
const FlamesOfColors = props => {
  return (
    <>
    {
      isMobileOnly ? 

      <div className='fd_flames_of_color_mobile fd_spacve_one d-lg-none'>
      <Container className='showcase-container'>
          <Row className='d-flex align-items-center'> 
          <Col md={8} lg={7} sm={12} className=''>
          <div className='fd_falmes'>
            <img src={flames} alt='FLAMES OF COLOR'  className='img-fluid' />
            </div>
            </Col>
           <Col md={8} lg={7} sm={12} className=''>
            {/* <div className='fd_falmes mob_beig'> */}
              <div>
              <div className='fd_falms_colo'>
                  <h2 className='fd_headings'>FLAMES OF COLOR</h2>
                  <p className='fd_paras'>At Firerock, we craft distinctive jewelry pieces that add the perfect shimmer, sparkle, and beauty to your ensemble.  
                      All our diamonds are finely crafted and meet the highest standards of quality as per international certifications. 
                      When you choose our diamond jewelry pieces, you choose quality, precision, and passion. 
                      Shop through our exhaustive collection today!
                  </p>
                  <NavLink to="/jewelry/flames-of-color"><Button variant="outline-dark" className='firerock_button'>SHOP NOW</Button></NavLink>
              </div>
            </div>
           </Col>
          
          </Row>
      </Container>
      </div>

      : 
      (
        <div className='fd_flames_of_color'>
        <Container className='showcase-container'>
            <Row> 
             <Col md={12} lg={12} sm={12} >
                <div className='fd_falms_colo'>
                    <h2 className='fd_headings'>FLAMES OF COLOR</h2>
                    <p className='fd_paras'>At Firerock, we craft distinctive jewelry pieces that add the perfect shimmer, sparkle, and beauty to your ensemble.  
                        All our diamonds are finely crafted and meet the highest standards of quality as per international certifications. 
                        When you choose our diamond jewelry pieces, you choose quality, precision, and passion. 
                        Shop through our exhaustive collection today!
                    </p>
                    <NavLink to="/jewelry/flames-of-color"><Button variant="outline-dark" className='firerock_button'>SHOP NOW</Button></NavLink>
                </div>
             </Col>
            
            </Row>
        </Container>
        </div>
      )
    }
   


   
    </>
  )
}

export default FlamesOfColors
