import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { base_url, postHeader } from "../../Helpers/request";
import { useState } from "react";
import { toast } from "react-toastify";
import LazyLoad from "react-lazy-load";
import BannerDesktop from "../../Assets/images/firerock-daimonds-img/home-banner-desktop.webp";
import BannerMobile from "../../Assets/images/firerock-daimonds-img/home-banner-mobile.webp";

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 800);

    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          //setLoading(false);

          setTimeout(() => {
            setLoading(false);
          }, 300);
          setBanner(response.data.data);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (


    <>
      {loading ? (
        <Skeleton variant="text" animation="wave" style={{
          height: "100vh", transformOrigin: "0px 0px", transform: "none",
        }} />

      ) : (
        <div
          className="rcs_hero_slider"
          style={{
            backgroundImage: `url(${isMobileOnly ? BannerMobile : BannerDesktop
              })`,
          }}
        >
          <div className="rcs_hero_img">
            <Container className="showcase-container">
              <Row className="m-auto w-100">
                <Col
                  md={6}
                  className="p-0 d-flex align-items-center justify-content-start frd_banner_mobile_align"
                >
                  <div
                    className={`${isMobile ? "text-md-center text-center" : "text-left"
                      } text-left`}
                  >
                    <h1 data-aos="fade-up-left" data-aos-duration="700">
                      Luxury in Every Detail
                    </h1>
                    <p
                      data-aos="fade-up-left"
                      data-aos-duration="1200"
                      data-aos-delay="300"
                    >
                      Adorn yourself with timeless beauty
                    </p>
                    <Button
                      data-aos="fade-up-left"
                      data-aos-duration="1800"
                      data-aos-delay="400"
                      onClick={() => {
                        history.push("/ringsettings");
                      }}
                      className="outline-base-btn large"
                    >
                      Shop Rings
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>


  );
  return (
    <>
      {banner?.map((val, index) => (
        <>
          {loading ? (
            <Skeleton variant="text" animation="wave" />

          ) : (
            <div
              className="rcs_hero_slider"
              style={{
                backgroundImage: `url(${isMobileOnly ? BannerMobile : BannerDesktop
                  })`,
              }}
            >
              <div className="rcs_hero_img">
                <Container className="showcase-container">
                  <Row className="m-auto w-100">
                    <Col
                      md={6}
                      className="p-0 d-flex align-items-center justify-content-start frd_banner_mobile_align"
                    >
                      <div
                        className={`${isMobile ? "text-md-center text-center" : val.text_direction_class
                          } text-left`}
                      >
                        <h1 data-aos="fade-up-left" data-aos-duration="700">
                          Luxury in Every Detail
                        </h1>
                        <p
                          data-aos="fade-up-left"
                          data-aos-duration="1200"
                          data-aos-delay="300"
                        >
                          Adorn yourself with timeless beauty
                        </p>
                        <Button
                          data-aos="fade-up-left"
                          data-aos-duration="1800"
                          data-aos-delay="400"
                          onClick={() => {
                            history.push("/ringsettings");
                          }}
                          className="outline-base-btn large"
                        >
                          Shop Rings
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default BannerSlider;
