import React from 'react'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import etpromise from '../../../../Assets/images/firerock-daimonds-img/eternalpromise.png'
import etspark from '../../../../Assets/images/firerock-daimonds-img/eternalsparkel.png'
import { Link, NavLink } from 'react-router-dom';

const EternalRings = () => {
  return (
    <>
    <div className='fd_eternalrings fd_spacve_one'>
        <Container className='showcase-container'>
        <Row>
            <Col lg={6} md={6} sm={12}>
            <div className='fd_eternal_rings'>
                <div className='fd_promiserings'>
                <img src={etpromise} alt='Eternal Promise' className='img-fluid'/>
                <div className='fd_white_box'>
                    <h3 className='fd_headings'>Eternal Promise</h3>
                    <NavLink to="/ringsettings"><h5 className='fd_paras'>SHOP NOW</h5></NavLink>
                </div>
                </div>
            </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
            <div className='fd_eternal_rings fd_etspace'>
                <div className='fd_promiserings'>
                <img src={etspark} alt='Eternal Sparkle' className='img-fluid'/>
                <div className='fd_white_box'>
                    <h3 className='fd_headings'>Eternal Sparkle</h3>
                    <NavLink to="/jewelry/wedding-rings"><h5 className='fd_paras'>SHOP NOW</h5></NavLink>
                </div>
                </div>
            </div>
            </Col>
        </Row>
        </Container>
    </div>
    </>
  )
}

export default EternalRings
